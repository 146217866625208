// @params
// - type (string) = "session" || "local"
// - field (string)
// - data (all || all[])

export const setStorageItem = ({ type, field, data }) => {
  if (type === 'session') {
    sessionStorage.setItem(field, data)
  } else if (type === 'local') {
    localStorage.setItem(field, data)
  } else {
    console.error('[setStorageItem] Clarify what storage type you want to set data in!')
  }
}

export const getStorageItem = ({ type, field }) => {
  if (type === 'session') {
    return JSON.parse(sessionStorage.getItem(field))
  } else if (type === 'local') {
    return JSON.parse(localStorage.getItem(field))
  } else {
    console.error('[getStorageItem] Clarify what storage type you want to get data from!')
  }
}

export const removeStorageItem = ({ type, field }) => {
  if (type === 'session') {
    sessionStorage.removeItem(field)
  } else if (type === 'local') {
    localStorage.removeItem(field)
  } else {
    console.error('[removeStorageItem] Clarify what storage type you want to remove data!')
  }
}
