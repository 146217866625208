export const formatChatMessage = (text, sender) => {
  return { text, sender, id: generateUniqueValue(sender), currentDate: getHoursAndMinutes() }
}

const generateUniqueValue = (text) => {
  return `${text}_${Date.now()}`
}

const getHoursAndMinutes = () => {
  const currentDate = new Date()
  return currentDate.getHours() + ':' + currentDate.getMinutes()
}
