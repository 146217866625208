import { useState } from 'react'
import { formatChatMessage } from '../../helpers/utils'
import { SenderType } from '../../helpers/constants'
import { fetchMessage } from '../../api/api'

import { Button } from '../ui'

function InputMessage({ messages, addMessage, setLoading }) {
  const [input, setInput] = useState('')

  const sendMessage = async (e) => {
    e.preventDefault()

    if (input.length <= 1) {
      alert('Please, input your message from 2 characters!')
    } else {
      try {
        // firstly we send message to the server
        const requestData = { input }
        setLoading(true)
        const output = await fetchMessage(requestData)

        if (output) {
          // if there isn't a error, we show user message in chat
          const formattedMyMessage = formatChatMessage(input, SenderType.Me)
          addMessage(formattedMyMessage)
          // and clear input
          setInput('')

          const formattedMessage = formatChatMessage(output, SenderType.Clare)
          addMessage(formattedMessage)
        }
      } catch (error) {
        // if there is an error, we stay user text in input so user doesn't need to send again
        alert(
          `Something happened while sending your message: ${error.message}. Please, wait couple of seconds and send again`,
        )
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <section className="fixed__container chat-form__container">
      <form className="form">
        <input
          className="form__input"
          placeholder="Type your message here"
          value={input}
          name="message"
          onChange={(e) => setInput(e.target.value)}
          required
          type="text"
        />
        <Button type="submit" onClick={sendMessage}>
          Send
        </Button>
      </form>
    </section>
  )
}

export default InputMessage
