import { useState, useEffect } from 'react'
import ChatHistory from '../components/Chat/ChatHistory'
import InputMessage from '../components/Chat/InputMessage'
import ChatManager from '../components/Chat/ChatManager'
import { setStorageItem, getStorageItem } from '../helpers/storage.js'

function ChatPage() {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const savedMessages = getStorageItem({ type: 'session', field: 'chatHistory' })
    if (savedMessages && savedMessages.length > 0) setMessages(savedMessages) // if there are data in sessionStorage
    else setMessages([]) // otherwise to reset values. It's for case of double rerendering component and sessionStorage issue
  }, [])

  function addMessage(message) {
    setMessages((prevMessages) => {
      return [...prevMessages, message]
    })
  }

  function clearMessages(message) {
    setMessages([])
  }

  const saveChatHistory = (e) => {
    e.preventDefault()

    setStorageItem({ type: 'session', field: 'chatHistory', data: JSON.stringify(messages) })
  }

  return (
    <>
      <ChatManager clearMessages={clearMessages} saveChatHistory={saveChatHistory} />
      <ChatHistory messages={messages} loading={loading} />
      <InputMessage messages={messages} addMessage={addMessage} setLoading={setLoading} />
    </>
  )
}

export default ChatPage
