const env = process.env

const config = {
  server: {
    httpLink: env.REACT_APP_HTTP_BACKEND_URL,
    wssLink: env.REACT_APP_WSS_BACKEND_URL,
  },
}

export default config
