import { ChatPage, TranscriptionPage } from '../pages'
import { PATHS } from '../helpers/constants'

export const routers = [
  {
    path: '/',
    navigate: PATHS.CHAT,
  },
  {
    path: PATHS.CHAT,
    element: <ChatPage />,
  },
  {
    path: PATHS.CALL,
    element: <TranscriptionPage />,
  },
]
