import { Button } from '../ui'
import { removeStorageItem } from '../../helpers/storage.js'

const MessageStorageManager = ({ clearMessages, saveChatHistory }) => {
  const removeMessages = (e) => {
    e.preventDefault()
    removeStorageItem({ type: 'session', field: 'chatHistory' })
    clearMessages()
  }

  return (
    <div className="store__container">
      <Button primary={'green'} onClick={saveChatHistory}>
        Save history
      </Button>
      <Button primary={'red'} onClick={removeMessages}>
        Clear messages
      </Button>
    </div>
  )
}

export default MessageStorageManager
