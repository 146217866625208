import { $request, $secureRequest } from './index'

export const fetchMessage = async (body) => {
  console.log('[fetchMessage] requestData: ', body)
  try {
    const response = await $request.post(`chat`, {
      body: JSON.stringify(body),
    })
    const { result } = await response.json()
    console.log(`result: `, result)

    return result
  } catch (error) {
    const errorMessage = `[Error] Fetch error: ${error.message}`
    console.error(errorMessage)
    alert(errorMessage)
  }
}

export const triggerCallRequest = async (number) => {
  try {
    
    const response = await $secureRequest
      .post(`call/trigger/${number}`, {
        hooks: {
          afterResponse: [
            // (_request, _options, response) => {
            //   // You could do something with the response, for example, logging.
            //   console.warn(`warn response: `, response)
            // },
            async (request, options, response) => {
              // if token is empty or token is not valid
              console.log(`response before condition 401/403: `, response)
              if (response.status === 403 || response.status === 401) {
                const { token } = await $request
                  .post('auth/generate-token', {
                    body: JSON.stringify({ phoneNumber: number }),
                  })
                  .json()

                localStorage.setItem('token', token)
                request.headers.set('Authorization', `Bearer ${token}`)
                request.headers.set('x-access-token', `${token}`)

                return $request(request)
              }
            },
          ],
        },
      })
      .json()

    return response
  } catch (error) {
    const errorMessage = `[Error] Fetch error: ${error.message}`
    console.error(errorMessage)
  }
}
