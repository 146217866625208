import MessageStorageManager from './MessageStorageManager'

const ChatManager = ({ clearMessages, saveChatHistory }) => {
  return (
    <section className="fixed__container chat-manager__container">
      <MessageStorageManager clearMessages={clearMessages} saveChatHistory={saveChatHistory} />
    </section>
  )
}

export default ChatManager
