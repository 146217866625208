import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { formatChatMessage } from '../helpers/utils'

export const useLiveConversationStore = create()(
  devtools((set, get) => ({
    phoneNumber: '',
    setPhoneNumber: (phoneNumber) => {
      set((state) => ({ phoneNumber }))
    },
    isCallTriggered: false,
    setIsCallTriggered: (status) => set(() => ({ isCallTriggered: status })),
    liveTranscriptions: [],
    addLiveTranscription: ({ text, sender }) => {
      const newTranscription = formatChatMessage(text, sender)
      const { liveTranscriptions } = get()
      set({ liveTranscriptions: [...liveTranscriptions, newTranscription] })
      // set({ liveTranscriptions: [newTranscription].concat(liveTranscriptions) }) // another order
    },
    cleanTranscriptions: () => {
      set({ liveTranscriptions: [] })
    },
  })),
)
