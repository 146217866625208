import { NavLink } from 'react-router-dom'
import logoSVG from '../../assets/logo.svg'
import { PATHS } from '../../helpers/constants'

const Header = () => {
  const getNavClass = ({ isActive }) => `header-nav__link${isActive ? ` header-nav__link--active` : ``}`

  return (
    <header className="fixed__container">
      <div className="header__container">
        <a href="https://clareandme.com/" className="header-logo__link">
          <img src={logoSVG} alt="Clare logo" className="header-logo__img" />
        </a>
        <nav className="header__nav">
          <ul className="header-nav__list">
            <li className="header-nav__item">
              <NavLink className={getNavClass} to={PATHS.CHAT}>
                Chat
              </NavLink>
            </li>
            <li className="header-nav__item">
              <NavLink className={getNavClass} to={PATHS.CALL}>
                Call
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
