import { SenderType } from '../../helpers/constants'
import loadIcon from '../../assets/loading.svg'
import { useAutoScroll } from '../../helpers/hooks.js'

const ChatHistory = ({ messages, loading }) => {
  const [messageEnd] = useAutoScroll(messages)

  return (
    <section className="section__container fixed__container chat-history__container">
      <ul className="chat-history chat__list" id="chatList">
        {messages.length <= 0 && !loading ? (
          <span className="absolute-center chat-history__holder">
            You communication with Clare will be here.
            <br />
            Write your message :D
          </span>
        ) : (
          <>
            {messages.map(({ id, text, sender }) => (
              <li
                key={id}
                className={`chat__item ${sender === SenderType.Me ? `chat__item--right` : `chat__item--left`}`}
              >
                <div className="chat-item__block">
                  <p className="chat-item__message">{text}</p>
                </div>
              </li>
            ))}
            <div ref={messageEnd}></div>
          </>
        )}
        {loading && (
          <li className={`chat__item chat__item--left`}>
            <div className="chat-item__block chat__load">
              <img className="chat-load__image" src={loadIcon} alt="Loading..." />
            </div>
          </li>
        )}
      </ul>
    </section>
  )
}

export default ChatHistory
