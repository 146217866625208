import { useState, useEffect, useRef } from 'react'
import config from '../../config/config.js'
import { SenderType } from '../../helpers/constants.js'
import { useAutoScroll } from '../../helpers/hooks.js'
import { useLiveConversationStore } from '../../store/liveConversationStore'

const wssLink = config.server.wssLink

const TranscriptionList = () => {
  const [isWebsocketConnected, setIsWebsocketConnected] = useState(false)
  const [liveTranscriptions, addLiveTranscription, phoneNumber, isCallTriggered, setIsCallTriggered] =
    useLiveConversationStore((state) => [
    state.liveTranscriptions,
    state.addLiveTranscription,
    state.phoneNumber,
    state.isCallTriggered,
      state.setIsCallTriggered,
  ])
  const [messageEnd] = useAutoScroll(liveTranscriptions)

  const socket = useRef()

  // TODO: consider a case when user speaks but is outside the page, e.g. he is in first page
  // in this case there is a scroll down after returning back to the current page

  useEffect(() => {
    isCallTriggered && setIsCallTriggered(false)
  }, [])

  useEffect(() => {
    if (isCallTriggered) {
      if (!socket.current) {
        console.log(`[CallTriggered, ws is null] Call is triggered`)

        const encodedPhoneNumber = encodeURIComponent(phoneNumber)
        const wssConnectionLink = `${wssLink}/?phoneNumber=${encodedPhoneNumber}`
        socket.current = new WebSocket(wssConnectionLink)
        console.log(`[CallTriggered] ws is connecting. WS status: ${socket.current.readyState}`)
      }
      websocketConnect()
    } else if (!isCallTriggered && socket.current) {
      console.log(`SHUT: isCallTriggered: ${isCallTriggered} && socket status: ${socket.current.readyState}`)
      socket.current?.close()
      setIsWebsocketConnected(false)
    }

    return () => {
      socket.current?.close()
      setIsWebsocketConnected(false)
    }
  }, [isCallTriggered])

  function websocketConnect() {
    socket.current.onopen = () => {
      if (socket.current.readyState === WebSocket.OPEN) {
        setIsWebsocketConnected(true)
        const message = {
          event: 'connection',
          phoneNumber,
          currentDate: Date.now(),
        }
        socket.current.send(JSON.stringify(message))
      }
    }

    socket.current.onmessage = (msg) => {
      const data = JSON.parse(msg.data)

      let sender = ''
      if (data.event === 'user') {
        sender = SenderType.Me
      } else if (data.event === 'clare') {
        sender = SenderType.Clare
      }

      addLiveTranscription({ text: data.payload.message, sender })
    }

    socket.current.onclose = () => {
      const socketMessage = 'Socket is closed'
      console.log(socketMessage)
    }

    socket.current.onerror = () => {
      const errorMessage = 'Socket error'
      console.log(errorMessage)
    }
  }

  if (!isCallTriggered)
    return (
      <span className="absolute-center chat-history__holder">
        Enter your phone number and click the &laquo;Trigger call now&raquo; button to&nbsp;start our conversation
      </span>
    )

  if (!isWebsocketConnected)
    return (
      <span className="absolute-center chat-history__holder">
        The app is&nbsp;connecting to&nbsp;the server. This may take a&nbsp;moment. Once the connection
        is&nbsp;established, your conversation will be&nbsp;available here.
      </span>
    )

  return (
    <ul className="chat-history chat__list" id="chatList">
      {liveTranscriptions.length <= 0 && (
        <span className="absolute-center chat-history__holder">
          Answer the incoming call, your chat will stay active here
        </span>
      )}
      {liveTranscriptions.map(({ id, text, sender }) => (
        <li key={id} className={`chat__item ${sender === SenderType.Me ? `chat__item--right` : `chat__item--left`}`}>
          <div className="chat-item__block">
            <p className="chat-item__message">{text}</p>
          </div>
        </li>
      ))}
      <div ref={messageEnd}></div>
    </ul>
  )
}

export default TranscriptionList
