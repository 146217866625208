import React from 'react'
import Router from 'react-easy-router'
import { routers } from './routers.js'

const AppRouter = () => {
  const routes = [...routers]
  return <Router routes={routes} />
}

export default AppRouter
