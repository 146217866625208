import { useRef, useEffect } from 'react'

export const useAutoScroll = (dataToTrigger) => {
  const messageEnd = useRef(null)

  useEffect(() => {
    messageEnd.current?.scrollIntoView()

    return () => {}
  }, [dataToTrigger])

  return [messageEnd]
}
