import { CallTrigger, TranscriptionList } from '../components/Call'

const TranscriptionPage = () => {
  return (
    <section className="section__container fixed__container">
      <CallTrigger />
      <TranscriptionList />
    </section>
  )
}

export default TranscriptionPage
