import { useState } from 'react'
// import PhoneInput from 'react-phone-number-input'
// import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { Button } from '../ui'
import { triggerCallRequest } from '../../api/api.js'
import { useLiveConversationStore } from '../../store/liveConversationStore'

const CallTrigger = () => {
  const [number, setNumber] = useState('')

  const [setPhoneNumber, setIsCallTriggered, cleanTranscriptions] = useLiveConversationStore((state) => [
    state.setPhoneNumber,
    state.setIsCallTriggered,
    state.cleanTranscriptions,
  ])

  const triggerCall = async (e) => {
    e.preventDefault()
    // if (isPossiblePhoneNumber(number)) {
    try {
      if (number.length >= 11 && number.length <= 17) {
        setIsCallTriggered(false)
        cleanTranscriptions()

        const response = await triggerCallRequest(number)
        if (response?.success) {
          // reloadWSConnection()
          setPhoneNumber(number)
          setIsCallTriggered(true)
        } else {
          alert('Something went wrong in triggering a call. Try a bit later. Sorry :( ')
        }
      } else if (number.length > 17) {
        alert("Your phone number has more that 17 characters. It's not valid number!")
      } else {
        alert('Your phone number has to have 11 characters at least!')
      }
    } catch (error) {
      setIsCallTriggered(false)
      const errorMessage = `Something happened in triggering a call: ${error.message}`
      console.log(errorMessage)
      alert(`${errorMessage}. Click the button again or a bit later`)
    }

    // } else {
    // alert('Please, enter valid number!')
    // }
  }

  return (
    <div className="call-trigger__container">
      {/* https://catamphetamine.gitlab.io/react-phone-number-input/ */}
      {/* <PhoneInput
        international
        limitMaxLength
        defaultCountry="DE"
        placeholder="445556667778"
        value={number}
        onChange={setNumber}
      /> */}
      <input
        className="form__input"
        type="tel"
        id="phone"
        name="phone"
        placeholder="+445556667778"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        required
        minLength="11"
        maxLength="17"
      />
      <Button primary={'blue'} onClick={triggerCall}>
        Trigger call now
      </Button>
    </div>
  )
}

export default CallTrigger
